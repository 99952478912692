import Vue from 'vue';
import VueI18n from 'vue-i18n';
import messages from '@/lang';
import axios from 'axios';
import * as Sentry from '@sentry/vue';

Vue.use(VueI18n);

export enum Language {
  EN = 'en',
  NL = 'nl',
  FR = 'fr',
  DE = 'de',
  ES = 'es',
  TEMPLATE = 'template', // DEBUG ONLY
}

export const supportedUserLanguages = [
  { key: 'Nederlands', value: Language.NL },
  { key: 'English', value: Language.EN },
  { key: 'Français', value: Language.FR },
  { key: 'Español', value: Language.ES },
];

const DEFAULT_LANGUAGE: Language = Language.EN;

export const DEFAULT_I18N = new VueI18n({
  messages,
  locale: DEFAULT_LANGUAGE,
  fallbackLocale: DEFAULT_LANGUAGE,
  missing: missingTranslationHandler,
});

const loadedLanguages: Language[] = [DEFAULT_LANGUAGE];

export async function newi18n(lang: Language): Promise<VueI18n> {
  if (!loadedLanguages.includes(lang)) {
    await fetchLanguage(lang);
  }
  return new VueI18n({
    messages,
    locale: lang,
    fallbackLocale: Language.EN,
  });
}

function setI18nLanguage(lang: Language): Language {
  DEFAULT_I18N.locale = lang;
  axios.defaults.headers.common['Accept-Language'] = lang;

  const $html = document.querySelector('html');
  if ($html) {
    $html.setAttribute('lang', lang);
  }

  return lang;
}

export async function loadLanguageAsync(lang: Language): Promise<Language> {
  if (DEFAULT_I18N.locale !== lang) {
    if (!loadedLanguages.includes(lang)) {
      return loadLanguage(lang);
    }
    return setI18nLanguage(lang);
  }
  return lang;
}

export function toLanguage(lang: string): Language {
  const splitLang = lang.split(/[-_]/)[0].toLowerCase() as Language;
  return Object.values(Language).includes(splitLang) ? splitLang : Language.EN;
}

export async function setLanguageFromNavigator(): Promise<void> {
  await loadLanguageAsync(toLanguage(window.navigator.language.slice(0, 2)));
}

async function fetchLanguage(lang: Language): Promise<void> {
  const msgs = await import('@/lang/' + lang + '/index.ts');
  DEFAULT_I18N.setLocaleMessage(lang, msgs.default);
  loadedLanguages.push(lang);
}

async function loadLanguage(lang: Language): Promise<Language> {
  await fetchLanguage(lang);
  return setI18nLanguage(lang);
}

function missingTranslationHandler(locale: string, key: string): void {
  Sentry.captureMessage(`Missing translation for ${key} in locale ${locale}`, {
    level: 'warning',
    extra: {
      locale,
      key,
    },
  });
}

export function getCountryList(
  i18n: VueI18n,
): { code: string; name: string }[] {
  const list: { code: string; name: string }[] = [];
  const countries = i18n.t('_.country');

  for (const key of Object.keys(countries)) {
    list.push({
      code: key,
      name: countries[key] as string,
    });
  }

  return list;
}

export function getMonthList(i18n: VueI18n): string[] {
  const countries = i18n.t('_.months');
  return Object.keys(countries).map((idx) => countries[idx] as string);
}
