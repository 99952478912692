import { BIG_ROUND_HALF_EVEN } from '@/lib/constants';
import { autoserializeAs, Deserialize, Serialize } from 'cerialize';
import { uuidv4 } from '@/lib/uuid';

import Big from 'big.js';
import { InvoiceLedger, InvoiceVAT } from '.';

export const LINE_IMPORT_PROPERTIES = [
  'quantity',
  'description',
  'ledger',
  'vat',
  'price',
];

export const LINE_IMPORT_TRANSLATIONS = [
  'quantity',
  'description',
  'ledgernumber',
  'vat',
  'unitPrice',
];

/**
 * InvoiceLine represents a single line on an Invoice
 */
export class InvoiceLine {
  /**
   * Line is the line number of this line.
   *
   * @deprecated
   */
  @autoserializeAs('number') line: number;

  /**
   * Quantity of the item represented by this line.
   */
  quantity: Big | null;

  /**
   * Description of the item represented by this line
   */
  @autoserializeAs('description') description: string;

  /**
   * Per unit price of the item represented by this line
   */
  price: Big | null;

  /**
   * VAT code data for this line
   * @type {InvoiceVAT | null}
   */
  @autoserializeAs('vat') vat: InvoiceVAT | null = null;

  /**
   * Ledgernumber data for this line
   * @type {InvoiceLedger | null}
   */
  @autoserializeAs('ledger') ledger: InvoiceLedger | null = null;

  /**
   * Does this line include VAT?
   * Not serialized
   */
  includesVat: boolean;

  /**
   * Line specific discount price
   */
  discount: Big = new Big(0);

  /**
   * UUID generated at line constructor.
   * Only used for dedup, not used anywhere else
   */
  uuid: string;

  constructor(includesVat: boolean = false) {
    this.quantity = null;
    this.description = '';
    this.price = null;
    this.includesVat = includesVat;
    this.uuid = uuidv4();
  }

  get vatFactor(): Big {
    return this.vat ? new Big(this.vat.factor) : new Big(0);
  }

  get vatAmount(): Big {
    if (this.vatFactor.eq(0)) {
      return new Big(0);
    }

    if (this.includesVat) {
      return this.subTotalInclVat.sub(this.subTotalInclVat.div(this.vatFactor));
    }

    return this.subTotalExclVat.mul(this.vatFactor.sub(1));
  }

  get subTotalExclVat(): Big {
    if (this.includesVat) {
      return this.subTotalInclVat
        .sub(this.vatAmount)
        .round(2, BIG_ROUND_HALF_EVEN);
    }
    return this.realQuantity
      .mul(this.realPrice)
      .sub(this.discount)
      .round(2, BIG_ROUND_HALF_EVEN);
  }

  get subTotalInclVat(): Big {
    if (this.includesVat) {
      return this.realQuantity
        .mul(this.realPrice)
        .sub(this.discount)
        .round(2, BIG_ROUND_HALF_EVEN);
    }

    return this.subTotalExclVat
      .add(this.vatAmount)
      .round(2, BIG_ROUND_HALF_EVEN);
  }

  /**
   * For the retards among us
   */
  get subTotal(): Big {
    return this.realQuantity.mul(this.realPrice).round(2, BIG_ROUND_HALF_EVEN);
  }

  /**
   * Like subTotal, but with the single line discount subtracted
   */
  get subTotalInclDiscount(): Big {
    return this.realQuantity
      .mul(this.realPrice)
      .sub(this.discount)
      .round(2, BIG_ROUND_HALF_EVEN);
  }

  get valid(): boolean {
    // Either it is a description only line
    return ((this.description &&
      this.quantity === null &&
      this.price === null &&
      !this.vat &&
      !this.ledger) ||
      // TODO(joost 11-10-2021): TS inference is wild here, not sure if TS is paranoid or if we're really
      // doing something extremely stupid here
      (this.description && this.vat && this.price && this.ledger)) as boolean;
  }

  get realPrice(): Big {
    if (this.price === null) {
      return new Big(0);
    }

    return this.price;
  }

  get realQuantity(): Big {
    if (this.quantity === null) {
      return new Big(1);
    }

    return this.quantity;
  }

  get errorMessage(): string | null {
    if (!this.description) {
      return 'missing description';
    }

    if (!this.isDescriptionOnly) {
      // We don't check quantity, calculations will default it to 1

      if (!this.price) {
        return 'invalid price';
      }

      if (!this.vat) {
        return 'missing VAT';
      }

      if (!this.ledger) {
        return 'missing ledgernumber';
      }
    }

    return null;
  }

  get isDescriptionOnly(): boolean {
    return (this.description &&
      this.quantity === null &&
      this.price === null &&
      !this.vat &&
      // TODO(joost 11-10-2021): TS inference is wild here, not sure if TS is paranoid or if we're really
      // doing something extremely stupid here
      !this.ledger) as boolean;
  }

  static deserialize(json: Record<string, unknown>): InvoiceLine {
    return Deserialize(json, InvoiceLine);
  }

  public serialize(): Record<string, unknown> {
    return Serialize(this, InvoiceLine);
  }

  public static OnSerialized(
    instance: InvoiceLine,
    json: Record<string, unknown>,
  ): void {
    json['quantity'] =
      instance.quantity === null ? null : instance.quantity.toJSON();
    json['price'] = instance.price === null ? null : instance.price.toJSON();
    json['discount'] = instance.discount.toJSON();
  }

  public static OnDeserialized(
    instance: InvoiceLine,
    json: Record<string, any>,
  ): void {
    instance.vat = InvoiceVAT.deserialize(json.vat);
    instance.quantity = json.quantity === null ? null : new Big(json.quantity);
    instance.price = json.price === null ? new Big(0) : new Big(json.price);
    instance.discount =
      json.discount === null ? new Big(0) : new Big(json.discount);
  }
}
