import { AxiosResponse } from 'axios';
import * as Sentry from '@sentry/vue';
import Papa, { ParseResult } from 'papaparse';

export function clamp<T>(val: T, min: T, max: T): T {
  if (val < min) {
    return min;
  }
  if (val > max) {
    return max;
  }
  return val;
}

export async function sleep(ms: number): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function unwrapError(e: any): string {
  // Axios response!
  if (e.response) {
    const response = e.response as AxiosResponse;
    if (response.status >= 500 && response.status <= 599) {
      Sentry.captureException(e, {
        extra: {
          request: e.request,
          response: e.response,
        },
        fingerprint: [
          'servererror',
          response.config.url || 'UNKNOWN URL',
          `${response.status}`,
        ],
      });
    }
  }

  // TNI api expected error format
  if (e.response && e.response.data.error) {
    return e.response.data.error.message;
  }

  // Generic Error
  if (e.message) {
    return e.message;
  }

  // Fallback
  return 'Unknown error: ' + e;
}

export async function blobResponseToJson(
  response: AxiosResponse,
): Promise<Record<string, unknown>> {
  return await new Response(response.data).json();
}

export function extractFilename(response: AxiosResponse): string {
  const contentDisposition = response.request.getResponseHeader(
    'Content-Disposition',
  );
  const match = /filename="(.*)"/gi.exec(contentDisposition);
  return match ? match[1] : '';
}

export function readFileBase64(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = () => {
      resolve(btoa(reader.result as string));
    };
    reader.onerror = reject;
  });
}

export function parseCsv(file: File): Promise<ParseResult<any>> {
  return new Promise((resolve, reject) => {
    Papa.parse<any, File>(file, {
      skipEmptyLines: true,
      dynamicTyping: true,
      complete(results: ParseResult<any>): void {
        resolve(results);
      },
      error(error: Error): void {
        reject(error);
      },
    });
  });
}

export function isProduction(): boolean {
  return process.env.NODE_ENV === 'production';
}

export function currentAddress(): string {
  return `${window.location.protocol}//${window.location.host}`;
}

export function headOrSelf<T>(item: T | T[]): T {
  if (item instanceof Array) {
    return item[0];
  }
  return item;
}

export function getContentType(fileName: string): string {
  const fileNameSections = fileName.split('.');

  switch (fileNameSections[fileNameSections.length - 1]) {
    case 'html':
      return 'text/html';
    case 'css':
      return 'text/css';
    case 'js':
      return 'application/javascript';
    case 'json':
      return 'application/json';
    case 'png':
      return 'image/png';
    case 'jpg':
      return 'image/jpeg';
    case 'gif':
      return 'image/gif';
    case 'pdf':
      return 'application/pdf';
    default:
      return 'application/octet-stream';
  }
}
